import React from "react";
import { Link } from "gatsby";
import { Navbar, Nav, Dropdown } from "rsuite";
import { BrowserView, MobileView } from "react-device-detect";
import { StaticImage } from "gatsby-plugin-image";
export default function NavBarTop() {
  return (
    <>
      <Navbar appearance="inverse" style={{ height: "100px", zIndex: "100" }}>
        <Navbar.Brand>
          <BrowserView>
            <Link to={`/`}>
              <StaticImage
                src="../../images/internal/logo-white.png"
                alt="Logo"
                style={{ width: "150px" }}
              />
            </Link>
          </BrowserView>
          <MobileView>
            <Link to={`/`}>
              <StaticImage
                src="../../images/internal/logo-white.png"
                alt="Logo"
                style={{ width: "150px" }}
              />
            </Link>
          </MobileView>
        </Navbar.Brand>
        <BrowserView>
          <Nav style={{ marginTop: "22px" }}>
            <Nav.Item style={{ padding: "9px 16px" }}>
              <Link
                to="/patient"
                style={{ fontSize: "15px", color: "white", fontWeight: "bold" }}
              >
                Patiënt
              </Link>
            </Nav.Item>
            <Nav.Item style={{ padding: "9px 16px" }}>
              <Link
                to="/professional"
                style={{ fontSize: "15px", color: "white", fontWeight: "bold" }}
              >
                Professional
              </Link>
            </Nav.Item>
            <Nav.Item style={{ padding: "9px 16px" }}>
              <Link
                to="/contact"
                style={{ fontSize: "15px", color: "white" }}
              >
                Contact
              </Link>
            </Nav.Item>
          </Nav>
        </BrowserView>
        <MobileView>
          <Dropdown
            title="menu"
            placement="bottomEnd"
            style={{ position: "absolute", top: "32px", right: "20px", zIndex: "2000" }}
          >
            <Dropdown.Item>
              <Link
                to="/patient"
                style={{ fontSize: "15px", fontWeight: "bold" }}
              >
                Patiënt
              </Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link
                to="/professional"
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
                Professional
              </Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link to="/contact" style={{ fontSize: "15px" }}>
                Contact
              </Link>
            </Dropdown.Item>
          </Dropdown>
        </MobileView>
      </Navbar>
    </>
  );
}
