import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import {
  Container,
  Content,
  CustomProvider,
  FlexboxGrid,
  Panel,
  Grid,
  Col,
} from "rsuite";
import "rsuite/styles/index.less";
import NavBarTop from "../ui/navBarTop";
import NavigationPanel from "../ui/navigationPanel";
import MarqueeLogo from "../ui/marqueeLogo";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import { isMobile } from "react-device-detect";

export default function LayoutStart() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <CustomProvider theme="light" locale="nl">
      <Helmet>
        <html lang="nl" />
        <title>{data.site.siteMetadata.title}</title>
        <meta
          name="description"
          content="Het doel van het Nederlands Kennisplatform Antistolling is om kennis, instrumenten en initiatieven op het gebied van antistollingszorg samen te brengen en toegankelijk te maken. Ben jij een patiënt of een professional met een vraag; het
          Kennisplatform leidt je naar een antwoord."
        />
      </Helmet>
      <Container style={{ minHeight: "100vh", background: "#F6F4F5" }}>
        <Content>
          <NavBarTop />
          <div
            style={{
              minHeight: "55vh",
              width: "100vw",
              position: "relative",
              backgroundColor: "#fff",
            }}
          >
            {!isMobile && (
              <FlexboxGrid
                align="middle"
                style={{
                  minHeight: "55vh",
                  maxWidth: "1120px",
                  margin: "0 auto",
                }}
              >
                <Col sm={24} md={16} lg={16}>
                  <Panel id="arrow-start">
                    <h1
                      style={{
                        fontSize: "2em",
                        lineHeight: "1.8",
                        textAlign: "left",
                        color: "#000",
                      }}
                    >
                      Welkom op het Nederlands Kennisplatform Antistolling. Hier
                      vind je alles over antistolling/ bloedverdunners.
                    </h1>
                    <span
                      style={{
                        fontWeight: "normal",
                        display: "block",
                        margin: "20px 0",
                        fontSize: "1.8em",
                        lineHeight: "1.7",
                      }}
                    >
                      Ben jij een patiënt of een professional met een vraag; het
                      Kennisplatform leidt je naar een antwoord.
                    </span>
                    <NavigationPanel title="Ga naar Patiënt" link="/patient/" />
                    <NavigationPanel
                      title="Ga naar Professional"
                      link="/professional/"
                    />
                  </Panel>
                </Col>
                <FlexboxGrid.Item as={Col} xs={4} md={5} lg={7}>
                  <div
                    style={{
                      width: "375px",
                      height: "375px",
                      position: "relative",
                    }}
                  >
                    <StaticImage
                      objectFit="cover"
                      src="../../images/internal/background1.jpeg"
                      alt="Afbeelding"
                      style={{
                        width: "375px",
                        height: "375px",
                        border: "20px solid white",
                        borderRadius: "1000px",
                        position: "relative",
                        top: "-15vh",
                      }}
                    />
                  </div>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            )}
            {isMobile && (
              <Grid>
                <Col sm={24} md={16} lg={16}>
                  <Panel id="arrow-start">
                    <h1
                      style={{
                        fontSize: "1.7em",
                        lineHeight: "1.8",
                        textAlign: "left",
                        color: "#000",
                      }}
                    >
                      Welkom op het Nederlands Kennisplatform Antistolling. Hier
                      vind je alles over antistolling/ bloedverdunners.
                      <span
                        style={{
                          fontWeight: "normal",
                          display: "block",
                          margin: "20px 0",
                          fontSize: "1em",
                        }}
                      >
                        Ben jij een patiënt of een professional met een vraag;
                        het Kennisplatform leidt je naar een antwoord.
                      </span>
                    </h1>
                    <NavigationPanel title="Ga naar Patiënt" link="/patient/" />
                    <NavigationPanel
                      title="Ga naar Professional"
                      link="/professional/"
                    />
                  </Panel>
                </Col>
              </Grid>
            )}
          </div>
          <div
            style={{
              backgroundColor: "#D72D22",
              height: "100px",
              width: "100vw",
            }}
          />
          <div>
            <FlexboxGrid
              justify="center"
              align="middle"
              style={{ padding: "0 0 75px 0" }}
            ></FlexboxGrid>
            <FlexboxGrid justify="center">
              <FlexboxGrid.Item>
                <span style={{ fontSize: "80%" }}>
                  Het {data.site.siteMetadata.title} is een samenwerking tussen:
                </span>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <MarqueeLogo />
          </div>
        </Content>
      </Container>
    </CustomProvider>
  );
}
