import React from "react";
import { Button } from "rsuite";
import { navigate } from "gatsby";

export default function NavigationPanel({ title, link }) {
  return (
    <>
      <Button
        appearance="primary"
        style={{ margin: "20px 10px 10px 0", padding: "15px", width: "250px", backgroundColor: "black" }}
        size="lg"
        onClick={() => navigate(link)}
      >
        {title}
      </Button>
    </>
  );
}
