import * as React from "react";
import LayoutStart from "components/layout/start";

const IndexPage = () => {
  return (
    <LayoutStart>
    <p>Test</p>
    </LayoutStart>
  );
};

export default IndexPage;
