import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function MarqueeLogo() {
  return (
    <>
      <div className="marquee-wrapper" style={{ margin: "40px 0" }}>
        <div className="marquee">
          <>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-niv.png"
                alt="Logo Nederlandse Internisten Vereniging"
                style={{ width: "250px" }}
              />
            </div>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-tsn.png"
                alt="Logo Trombosestichting Nederland"
                style={{ width: "140px" }}
              />
            </div>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-ctd.png"
                alt="Logo Clientenraad Trombosediensten"
                style={{ width: "200px" }}
              />
            </div>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-fms.png"
                alt="Logo Federate Medisch Specialisten"
                style={{ width: "150px" }}
              />
            </div>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-skms2.png"
                alt="Logo Stichting Kwaliteitsgelden Medisch Specialisten 2"
                style={{ width: "200px" }}
              />
            </div>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-fnt.png"
                alt="Logo Federatie Nederlandse Trombosediensten"
                style={{ width: "150px" }}
              />
            </div>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-nvvc.png"
                alt="Logo Nederlandse Vereniging Voor Cardiologie"
                style={{ width: "60px" }}
              />
            </div>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-nhg.png"
                alt="Logo Nederlands Huisartsen Genootschap"
                style={{ width: "150px" }}
              />
            </div>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-nvvh.png"
                alt="Logo Nederlandse Vereniging voor Heelkunde"
                style={{ width: "160px" }}
              />
            </div>
          </>
          <>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-niv.png"
                alt="Logo Nederlandse Internisten Vereniging"
                style={{ width: "250px" }}
              />
            </div>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-tsn.png"
                alt="Logo Trombosestichting Nederland"
                style={{ width: "140px" }}
              />
            </div>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-ctd.png"
                alt="Logo Clientenraad Trombosediensten"
                style={{ width: "200px" }}
              />
            </div>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-fms.png"
                alt="Logo Federate Medisch Specialisten"
                style={{ width: "150px" }}
              />
            </div>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-skms2.png"
                alt="Logo Stichting Kwaliteitsgelden Medisch Specialisten 2"
                style={{ width: "200px" }}
              />
            </div>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-fnt.png"
                alt="Logo Federatie Nederlandse Trombosediensten"
                style={{ width: "150px" }}
              />
            </div>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-nvvc.png"
                alt="Logo Nederlandse Vereniging Voor Cardiologie"
                style={{ width: "60px" }}
              />
            </div>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-nhg.png"
                alt="Logo Nederlands Huisartsen Genootschap"
                style={{ width: "150px" }}
              />
            </div>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-nvvh.png"
                alt="Logo Nederlandse Vereniging voor Heelkunde"
                style={{ width: "160px" }}
              />
            </div>
          </>
          <>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-niv.png"
                alt="Logo Nederlandse Internisten Vereniging"
                style={{ width: "250px" }}
              />
            </div>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-tsn.png"
                alt="Logo Trombosestichting Nederland"
                style={{ width: "140px" }}
              />
            </div>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-ctd.png"
                alt="Logo Clientenraad Trombosediensten"
                style={{ width: "200px" }}
              />
            </div>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-fms.png"
                alt="Logo Federate Medisch Specialisten"
                style={{ width: "150px" }}
              />
            </div>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-skms2.png"
                alt="Logo Stichting Kwaliteitsgelden Medisch Specialisten 2"
                style={{ width: "200px" }}
              />
            </div>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-fnt.png"
                alt="Logo Federatie Nederlandse Trombosediensten"
                style={{ width: "150px" }}
              />
            </div>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-nvvc.png"
                alt="Logo Nederlandse Vereniging Voor Cardiologie"
                style={{ width: "60px" }}
              />
            </div>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-nhg.png"
                alt="Logo Nederlands Huisartsen Genootschap"
                style={{ width: "150px" }}
              />
            </div>
            <div>
              <StaticImage
                objectFit="contain"
                src="../../images/logo/logo-nvvh.png"
                alt="Logo Nederlandse Vereniging voor Heelkunde"
                style={{ width: "160px" }}
              />
            </div>
          </> 
        </div>
      </div>
    </>
  );
}
